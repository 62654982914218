




















import GroupCover from '@/modules/community/common/components/group-cover.vue'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { myDaoSectionController } from './my-dao-section-controller'

@Observer
@Component({
  components: {
    GroupCover,
  },
})
export default class extends Vue {
  controller = myDaoSectionController
}
