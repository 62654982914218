import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { apiService } from '@/services/api-services'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class MyDaoSectionController {
  @observable myCommunities: CommunityModel[] = []

  constructor() {
    this.fetchData()
  }

  @asyncAction *fetchData() {
    try {
      const communities = yield apiService.daos.fetchDaos()
      if (communities.length) {
        this.myCommunities = communities
      }
    } catch (e) {
      snackController.commonError('Something went wrong when fetching community')
    }
  }
}

export const myDaoSectionController = new MyDaoSectionController()
